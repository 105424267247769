import { axiosInstance } from '../interceptors';

export const repaymentSettingsSave = async (request:any) => {
  return await axiosInstance.post(`/master/loanSetting`,request);
};

export const repaymentSettingsList = async () => {
  return await axiosInstance.get(`/master/loanSetting/list`)
};

export const repaymentSettingsGet = async (id: string) => {
  return await axiosInstance.get(`/master/loanSetting/get/${id}`)
};

export const repaymentSettingsUpdate = async (id: string,request:any) => {
  return await axiosInstance.put(`/master/loanSetting/update/${id}`,request)
};