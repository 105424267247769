import React, { useContext } from 'react';
import { MainLayout } from '../containers/layout';
import { Route, Routes, Navigate, useNavigate } from 'react-router-dom';
import Login from '../containers/views/login/login';
import ForgotPassword from '../containers/views/forgotpassword/forgotpassword';
import SetNewPaasword from '../containers/views/setnewpassword/setnewpassword';
import CheckMail from '../containers/views/checkmail/checkmail';
import { AuthLayout } from '../containers/layout';
import { LayoutProvider } from '../context';
import { AuthContext } from '../context';
import Home from '../containers/views/home/home';
import SerialNoList from '../containers/views/adminPanel/serialNo/SerialNoList';
import RepaymentSettings from '../containers/views/adminPanel/loanSettings/loanSettings';
import InterestRateList from '../containers/views/adminPanel/interestRate/InterestRateList';
import ChitInterestList from '../containers/views/adminPanel/chitInterest/chitInterestList';
import ProfileView from '../containers/views/profile/profileView';


const Index = () => {
  const { isAuthenticated } = useContext(AuthContext);
  
  return (
    <LayoutProvider>
      <Routes>
        {isAuthenticated ? (
          <>
            <Route path='/app' element={<MainLayout />}>
              <Route index element={ <Home/>} />
              <Route path='serialno' element={ <SerialNoList/>} />
              <Route path='loansettings' element={ <RepaymentSettings/>} />
              <Route path='interestsettings' element={ <InterestRateList/>} />
              <Route path='chitsettings' element={ <ChitInterestList/>} />
              <Route path='profile' element={<ProfileView />} />

              <Route path='*' element={<Navigate to='/login' replace/> } />
            </Route>
            </>
           
        ): null }
        <Route  path='/login'  element={<Login />} />
        <Route path='/auth' element={<AuthLayout />} />
        <Route path='/forgotpassword' element={<ForgotPassword />} />
        <Route path='/checkmail' element={<CheckMail />} />
        <Route path='/setnewpassword' element={<SetNewPaasword />} />
        <Route path='*' element={<Navigate to='/login' replace />} />
      </Routes>
    </LayoutProvider>
  );
  
};

export default Index;


