import { axiosInstance } from './interceptors';

export const paymentsByThisWeek= async () => {
    return await axiosInstance.get(`loan/receivedpaymentsbythisweek`);
};
export const getDuesList = async () => {
    return await  axiosInstance.get(`/loan/getdues?page=${1}&limit=${3}`);
}

export const addcapitalAmount = async (request: any) => {
    return await axiosInstance.post(`/master/capitalAmount`, request);
};
export const reducecapitalAmount = async (request: any) => {
    return await axiosInstance.post(`/master/decreaseCapitalAmount`, request);
};
export const updateCapitalAmount = async (request: any) => {
    return await axiosInstance.post(`/master/updateCapitalAmount`, request);
};

export const getCapitalAmount = async () => {
    return await axiosInstance.get(`/master/getCapitalAmount`);
};
export const getDashBoardReports = async () => {
    return await axiosInstance.get(`/master/getDashBoardReports`);
};