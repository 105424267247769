import React, { useRef, useState, useEffect } from 'react';

import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Paginator, PaginatorTemplateOptions } from 'primereact/paginator';
import { Dropdown } from 'primereact/dropdown';
import { serialSvc } from '../../../../services';
import { useTranslation } from 'react-i18next';
import SerialNoModal from './SerialNoModal';
import InputGroup from 'react-bootstrap/esm/InputGroup';
import '../../../../styles/css/master.css'
import ActionButton from '../../../../components/common/buttons/actionButton';
import { Heading } from '../../../../components/styledcomponents';

const SerialNoList = () => {
    const [page, setPage] = useState(0);
    const [first, setFirst] = useState(0);
    const [limit, setLimit] = useState(10);
    const [globalFilter, setGlobalFilter] = useState('');
    const [deleteDialog, setDeleteDialog] = useState(false);
    const [selected, setSelected] = useState([]);
    const [serialList, setSerialList] = useState([]);
    const [totalRecords, setTotal] = useState(0);
    const [visible, setVisible] = useState(false);
    const [visiblePermission, setVisiblePermission] = useState(false);
    const [edit, setEdit] = useState(true);
    const [id, setId] = useState<string>('');
    const dt = useRef<DataTable<any>>(null);
    const [t] = useTranslation()

    const getSerialNo = () => {
        serialSvc.list((page + 1), limit).then((res: any) => {
            setSerialList(res.data.res);
            setTotal(res.data.totalPages);
        });
    };
    const updateTableData = () => {
        serialSvc.list((page + 1), limit).then((res: any) => {
            setSerialList(res.data.res);
            setTotal(res.data.totalPages);
        });
    };
    const exportCSV = () => {
        if (dt.current)
            dt.current.exportCSV();
    };


    const actionBodyTemplate = (rowData: any) => {
        return (
            <>
                <ActionButton label={("Edit")} icon="pi pi-pencil" className="p-button-secondary" onClick={() => onEditOrView(true, rowData._id)} /> {" "}
            </>
        );
    };



    const paginatorTemp: PaginatorTemplateOptions = {
        layout: 'RowsPerPageDropdown CurrentPageReport PrevPageLink NextPageLink',
        RowsPerPageDropdown: (options) => {
            const dropdownOptions = [
                { label: 5, value: 5 },
                { label: 10, value: 10 },
                { label: 20, value: 20 },
                { label: 120, value: 120 }
            ];

            return (
                <React.Fragment>
                    <span className="mx-1 pagination-no">
                        {t('Items per page') + ': '}
                    </span>
                    <Dropdown value={options.value} options={dropdownOptions} onChange={options.onChange} />
                </React.Fragment>
            );
        },
        CurrentPageReport: (options: { first: string | number | boolean | React.ReactElement<any, string | React.JSXElementConstructor<any>> | React.ReactFragment | React.ReactPortal | Iterable<React.ReactNode> | null | undefined; last: string | number | boolean | React.ReactElement<any, string | React.JSXElementConstructor<any>> | React.ReactFragment | React.ReactPortal | Iterable<React.ReactNode> | null | undefined; totalRecords: string | number | boolean | React.ReactElement<any, string | React.JSXElementConstructor<any>> | React.ReactFragment | React.ReactPortal | Iterable<React.ReactNode> | null | undefined; }) => {
            return (
                <span className='pagination-no'>
                    {options.first} - {options.last} of {options.totalRecords}
                </span>
            );
        }
    };
    const onPageChange = (event: { first: React.SetStateAction<number>; rows: React.SetStateAction<number>; page: React.SetStateAction<number>; }) => {
        setFirst(event.first);
        setLimit(event.rows);
        setPage(event.page);
    };
    const onEditOrView = (edit: boolean, id?: string) => {
        setEdit(edit);
        setVisible(false);
        if (id)
            setId(id);
        setVisible(true);

    };
    useEffect(() => {
        getSerialNo();
    }, [page, limit]);
    useEffect(() => {
        if (!visible)
            setId('');
    }, [visible]);
    return (
        <React.Fragment>
            <div className="grid crud-demo">
                <div className="col-12">
                    <div ><Heading className="m-0">{t('Serial No')}</Heading></div>
                    <div className="master-toolbar">
                        <div >
                            <div className="search-bar mt-1">
                                <InputGroup>
                                    <span className="p-input-icon-left">
                                        <i className="pi pi-search" />
                                        <InputText
                                            onInput={(e: any) => setGlobalFilter(e.target.value)}
                                            placeholder={t("Search...") as string}
                                            style={{ width: '300px', borderRadius: '0px', height: '2.5rem' }}
                                        />
                                    </span>
                                    <Button label={t("Search") + ''} style={{ backgroundColor: '#11B3CF', height: '2.5rem' }} className='p-button-success' />
                                </InputGroup>
                            </div>
                            {/* <Button label={t("delete")+''} icon="pi pi-trash" className="p-button-danger p-button-sm" onClick={() => { }} disabled={!selected || !selected.length} style={{ display: selected && selected.length>=2 ? 'inline-block' : 'none' }} /> */}
                        </div>
                        <div>
                            <Button label={t("Add New") + ''} style={{ backgroundColor: '#11B3CF' }} icon="pi pi-plus" className="p-button-success p-button-sm mr-2" onClick={() => { onEditOrView(false); }} />
                            <Button icon="pi pi-file-excel" style={{ backgroundColor: '#11B3CF' }} className="p-button-success p-button-sm" onClick={exportCSV} />
                        </div>
                    </div>
                    <DataTable
                        ref={dt}
                        value={serialList}
                        selection={selected}
                        onSelectionChange={(e: any) => setSelected(e.value)}
                        dataKey="_id"
                        className="datatable-responsive master-datatable"
                        globalFilter={globalFilter}
                        emptyMessage="No data found."
                    >
                        <Column style={{ flexGrow: 1, flexBasis: '200px' }} selectionMode="multiple" headerStyle={{ width: '4rem' }}></Column>
                        <Column className="capitalize" style={{ flexGrow: 1, flexBasis: '200px' }} header={t("serial_no_type")} field='serial_no_type' ></Column>
                        <Column style={{ flexGrow: 1, flexBasis: '200px' }} header={t("prefix")} field='prefix' ></Column>
                        <Column style={{ flexGrow: 1, flexBasis: '200px' }} header={t("length")} field='length' ></Column>
                        <Column header={t("action")} style={{ textAlign: 'center', flexGrow: 1, flexBasis: '200px' }} alignHeader={'center'} body={actionBodyTemplate} ></Column>
                    </DataTable>
                    <Paginator template={paginatorTemp} first={first} rows={limit} totalRecords={totalRecords} onPageChange={onPageChange} className="justify-content-end my-3"></Paginator>

                    {/* <Dialog visible={deleteDialog} style={{ width: '450px' }} header="Confirm" modal onHide={() => setDeleteDialog(false)} footer={deleteDialogFooter}>
                        <div className="flex align-items-center justify-content-center">
                            <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                            {<span>Are you sure you want to delete the selected products?</span>}
                        </div>
                    </Dialog> */}
                </div>
            </div>
            {visible && <SerialNoModal visible={visible} setVisible={setVisible} id={id} updateTableData={updateTableData} />}
            {/* {visiblePermission && <RolePermission visible={visiblePermission} setVisible={setVisiblePermission} id={id} />} */}
        </React.Fragment>
    );
};

export default SerialNoList;