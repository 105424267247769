export const model = [
    {
        items: [{ label: 'dashboard', icon: 'pi pi-home', to: '/app' }],
        seperator: false
    },
    {
        to: '/app',
        items: [
            {
                label: 'Admin Panel',
                icon: 'pi pi-fw pi-cog',
                to: '/pages/timeline',
                items: [

                    
                            {
                                label: 'Serial No',
                                icon: 'pi pi-fw pi-list ',
                                to: '/app/serialno'
                            },
                            {
                                label: 'Interest Rate',
                                icon: 'pi pi-fw pi-money-bill',
                                to: '/app/interestsettings'


                            },
                            {
                                label: 'Loan Settings',
                                icon: 'pi pi-fw pi-calculator',
                                to: '/app/loansettings'
                            },
                            {
                                label: 'Chit Commision',
                                icon: 'pi pi-fw pi-chart-bar',
                                to: '/app/chitsettings'
                            }
                        
                ]
            },
        ]
    },
];
