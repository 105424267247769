import { Sidebar } from "primereact/sidebar";
import './header.css'
import { useEffect, } from "react";
import { useTranslation } from "react-i18next";

const childAppRentUrl = process.env.REACT_APP_CHILD_RENT_APP_URL;
const childAppLoanUrl = process.env.REACT_APP_CHILD_LOAN_APP_URL;
const childAppUserUrl = process.env.REACT_APP_CHILD_USER_APP_URL;
const childAppCustomerUrl = process.env.REACT_APP_CHILD_CUSTOMER_BASE_URL;
const childAppChitUrl = process.env.REACT_APP_CHILD_CHIT_BASE_URL;
const childAppReportUrl = process.env.REACT_APP_CHILD_REPORTS_APP_URL;
const childAppBaseUrl = process.env.REACT_APP_BASE_URL;
const childAppAccessManagementUrl=process.env.REACT_APP_CHILD_ACCESS_MANAGEMENT_APP_URL;
const childAppAgentManagementUrl=process.env.REACT_APP_CHILD_AGENT_MANAGEMENT_APP_URL;


interface ISiblingAppsSideBar {
    setVisible(val: boolean): void;
    visible: boolean;
}

const SiblingAppsSideBar = ({ visible, setVisible }: ISiblingAppsSideBar) => {
    const [t] =  useTranslation()
    const childApps: any = [
        { name: t("Base App"), url: childAppBaseUrl, icon: "pi pi-home" },
        { name: t('Rent App'), url: childAppRentUrl, icon: 'pi pi-wrench' },
        { name: t('Loan App'), url: childAppLoanUrl, icon: 'pi pi-money-bill' },
        { name: t('User App'), url: childAppUserUrl, icon: 'pi pi-user' },
        { name: t('Agent App'), url: childAppAgentManagementUrl, icon: "pi pi-user" },
        { name: t('Access Management'), url: childAppAccessManagementUrl, icon: "pi pi-cog" },
        { name: t('Customer App'), url: childAppCustomerUrl, icon: "pi pi-users" },
        { name: t("Inventory App"), url: "", icon: "pi pi-shopping-cart" },
        { name: t("Chit Management"), url: childAppChitUrl, icon: "pi pi-wallet" },
        { name: t("Reports"), url: childAppReportUrl, icon: "pi pi-book" },
    ];
    return (
        <Sidebar visible={visible} position="right" onHide={() => setVisible(false)} className="w-full md:w-20rem lg:w-30rem custom-sidebar">
            <h4>{t("More Apps")}</h4>
            <ul className="child-app-list">
                {childApps.map((app: any, index: any) => (
                    <li key={index}>
                        <a href={app.url} rel="noopener noreferrer">
                            <div className="app-card">
                                <i className={app.icon} style={{ fontSize: "30px" }}></i>
                                <span>{app.name}</span>
                            </div>
                        </a>
                    </li>
                ))}
            </ul>
        </Sidebar>
    )
}

export default SiblingAppsSideBar;